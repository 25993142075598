import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import bodyRenderer from 'utils/bodyRenderer';
import { getSectionAnchors } from 'utils/helpers';

import bodySections from './bodySections';
import { IPainCampaignPageProps } from './model';

import './PainCampaignPage.scss';

const PainCampaignPage: FC<IPainCampaignPageProps> = ({ data, pageContext: { breadcrumbs } }) => {
  const {
    page: {
      nodes: [
        { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, lang, body, ogImage },
      ],
    },
    siteSettings,
    header,
    footer,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    hcpPopup,
    pageLangs: { nodes: langs },
  } = data;

  const sectionAnchors = getSectionAnchors(body);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage
      }}
      siteSettings={siteSettings}
      header={header}
      footer={footer}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      hcpPopup={hcpPopup}
    >
      <div className="campaign-page-plain-holder">
        {bodyRenderer(body, bodySections, {
          sectionAnchors,
          breadcrumbs,
          ariaLabelPrev: siteSettings.ariaLabelPrev,
          ariaLabelNext: siteSettings.ariaLabelNext,
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageIdRegex: String) {
    page: allPainCampaignPage(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        lang
        ...FragmentSeo
        body {
          structure
          properties {
            showCarouselOnMobile
            facts {
              properties {
                description
                metric
                subtitle
                title
                image {
                  ...FragmentFluidSmallImage
                }
                imageAlt
              }
            }
            scale {
              properties {
                title
                scaleBg {
                  properties {
                    colorPicker {
                      label
                    }
                  }
                }
                label
              }
            }
            words {
              properties {
                word
                background {
                  properties {
                    colorPicker {
                      label
                    }
                  }
                }
              }
            }
            thingsList {
              properties {
                text
              }
            }
            rteTitle
            note
            sectionAnchor
            rememberItems {
              properties {
                title
                letter
                description
                itemBg {
                  properties {
                    colorPicker {
                      label
                    }
                  }
                }
                icon {
                  fallbackUrl
                  gatsbyImage {
                    childImageSharp {
                      fixed(height: 70, quality: 90) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
            withAnchorNavigation
            cta {
              icon
              name
              url
              queryString
            }
            title
            imageDescription
            fileLink {
              gatsbyDocFile {
                relativePath
              }
            }
            link {
              name
              target
              url
            }
            anchorText
            description
            sectionAnchor
            sectionBg {
              properties {
                colorPicker {
                  label
                  value
                }
              }
            }
            sectionBackground {
              properties {
                colorPicker {
                  label
                  value
                }
              }
            }
            imageBackground {
              properties {
                colorPicker {
                  label
                }
              }
            }
            image {
              fallbackUrl
              url
            }
            painPassBannerImage: image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              fallbackUrl
              url
            }
            backgroundImage {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            mobileBackgroundImage {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 992, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
            header
            headerSmall
            text
            sectionTheme
            shareBlock {
              properties {
                counter
                counterDescription
                description
                title
                link {
                  name
                  target
                  url
                }
                image {
                  ...FragmentFluidMiddleImage
                }
                imageAlt
              }
            }
            reports {
              properties {
                title
                description
                cta {
                  name
                  url
                }
                ctaAria
                image {
                  ...FragmentFluidMiddleImage
                }
                imageAlt
              }
            }
            items {
              properties {
                title
                description
                image {
                  ...FragmentFluidSmallImage
                }
              }
            }
            videoIframe
          }
        }
      }
    }
    pageLangs: allUmbracoHome(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default PainCampaignPage;
